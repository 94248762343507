import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { withNamespaces } from "react-i18next";

import "../../i18n";
import ContentLayout from "../../components/contentLayout";

const VeritableNewsPage = ({ t, data }) => {
  return (
    <ContentLayout>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="content">
                <h1 className="title is-2 center">{t("youtube8m")}</h1>
                <h4 className="subtitle center">
                  <a
                    href="https://github.com/ceshine/yt8m-2019"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Our Open-sourced Solution on Github
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-narrow has-text-centered">
              <a
                href="https://news.veritable.pw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fixed={data.image1.childImageSharp.fixed} />
              </a>
              <br />
              <a
                href="https://research.google.com/youtube8m/workshop2019/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                (image source)
              </a>
            </div>
          </div>
          <div className="columns is-multiline is-tablet">
            <div className="column is-10-desktop is-offset-1-desktop is-half-widescreen is-offset-3-widescreen">
              <div className="content">
                <p>{t("youtube8m-paragraph-1")}</p>
                <p>
                  <a
                    href="https://www.kaggle.com/c/youtube8m-2019/leaderboard"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The final leaderboard on Kaggle
                  </a>
                  {" | "}
                  <a
                    href="https://github.com/ceshine/yt8m-2019/blob/master/Lee2019.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The published workshop paper
                  </a>
                </p>
                <p>{t("youtube8m-paragraph-2")}</p>
                <div className="columns is-multiline is-centered">
                  <div className="column is-narrow">
                    <Img fixed={data.image2.childImageSharp.fixed} />
                  </div>
                </div>
                <p>{t("youtube8m-paragraph-3")}</p>
                <p>{t("youtube8m-paragraph-4")}</p>
                <p>{t("technology-used")}</p>
                <ul>
                  <li>
                    Hardware
                    <ul className="inner">
                      <li>(Local) GTX1070</li>
                      <li>(GCP) Tesla P100</li>
                      <li>(GCP) Tesla T4</li>
                    </ul>
                  </li>
                  <li>PyTorch 1.3.0</li>
                  <li>Tensorflow 2.0 (for loading .tfrecord files)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContentLayout>
  );
};

export default withNamespaces(["projects", "navbar"], {})(VeritableNewsPage);

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projects-yt8m-2019.png" }) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: { eq: "projects-yt8m-context-aware.png" }) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
